//jsxhook
import { Column } from "@sixty-six-north/ui-system"
import { NextPage } from "next"
import Head from "next/head"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Grid } from "theme-ui"
import JoinTheAdventure from "../auth/components/JoinTheAdventure"
import LoginBreadCrumbs from "../auth/components/LoginBreadCrumbs"
import { LoginContainer, LoginForm } from "../auth/components/LoginForm"
import Layout from "../components/Layout"
import { useDashboardLink } from "../components/Links"
import { useCustomerService } from "../customer/CustomerService"
import { GlobalProps } from "../next/GlobalProps"
import { getGlobalServerSideProps } from "../next/GlobalPropsService"
import { useRouter } from "../routing/useRouter"
import { createLogger } from "../utils/createLogger"

const logger = createLogger("login")

const Login: NextPage<GlobalProps> = props => {
  const router = useRouter()
  const dashboard = useDashboardLink()
  const { t } = useTranslation("login")

  const customerService = useCustomerService()
  const dashboardLink = useDashboardLink()

  useEffect(() => {
    customerService
      .fetchCustomer()
      .then(() => router.push(dashboardLink.href, dashboardLink.as))
      .catch(() => logger.info("Failed retrieving customer"))
  }, [])

  return (
    <Layout {...props}>
      <Head>
        <title>{[t("login"), "|", t("66north")].join(" ")} </title>
      </Head>
      <Grid gap={0} columns={["1fr", "1fr", null, "5fr 3fr"]}>
        <Column>
          <LoginBreadCrumbs />
          <LoginContainer>
            <LoginForm
              onLogin={() => router.push(dashboard.href, dashboard.as)}
            />
          </LoginContainer>
        </Column>

        <JoinTheAdventure />
      </Grid>
    </Layout>
  )
}

export const getServerSideProps = getGlobalServerSideProps

export default Login
