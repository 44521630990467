//jsxhook
import React from "react"
import {
  BreadCrumbItem,
  BreadCrumbs,
  Label2,
  StandardXAxisPadding
} from "@sixty-six-north/ui-system"
import { useTranslation } from "react-i18next"

export const LoginBreadCrumbs = () => {
  const { t } = useTranslation("login")
  return (
    <BreadCrumbs sx={{ pl: StandardXAxisPadding }}>
      <BreadCrumbItem>
        <Label2>{t("myAccount", { defaultValue: "My Account" })}</Label2>
      </BreadCrumbItem>
      <BreadCrumbItem>
        <Label2 sx={{ color: "blue.0" }}>
          {t("login", { defaultValue: "Log In" })}
        </Label2>
      </BreadCrumbItem>
    </BreadCrumbs>
  )
}

export default LoginBreadCrumbs
