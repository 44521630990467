import {
  ApiRequest,
  Address,
  ByProjectKeyRequestBuilder,
  ClientResponse,
  Customer
} from "@commercetools/platform-sdk"
import { useCommerceToolsClient } from "commercetools/CommerceToolsHooks"
import { CustomerState } from "customer/CustomerState"
import { CommerceToolsClient } from "../commercetools/CommerceToolsClient"
import { useAppDispatch } from "../State"

export class CustomerService {
  private readonly client: CommerceToolsClient | undefined

  constructor(client: CommerceToolsClient) {
    this.client = client
  }

  public fetchCustomer = (): Promise<Customer> => {
    return this.withClient(it => it.me().get()).then(response => response.body)
  }

  public addAddress = (
    customer: Customer,
    address: Address
  ): Promise<Customer> => {
    return this.withClient<Customer>(it =>
      it.me().post({
        body: {
          ...customer,
          actions: [
            {
              action: "addAddress",
              address
            }
          ]
        }
      })
    ).then(it => it.body)
  }

  private withClient = <T>(
    fn: (it: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ): Promise<ClientResponse<T>> => {
    if (this.client) {
      return this.client.execute(fn)
    } else {
      return Promise.reject("No commerce tools client")
    }
  }
}

export interface CustomerStateI {
  customer?: Customer
  loading: boolean
  currentRequestId?: string
}

export interface CustomerServiceWrapper {
  fetchCustomer: () => Promise<Customer>
  setCustomer: (customer: Customer) => Promise<Customer>
  addShippingAddress: (
    customer: Customer,
    address: Address
  ) => Promise<Customer>
}

export const useCustomerService = (): CustomerServiceWrapper => {
  const dispatch = useAppDispatch()
  const client = useCommerceToolsClient()
  const service = new CustomerService(client)

  const fetchCustomer = (): Promise<Customer> => {
    return service.fetchCustomer().then(customer => {
      return dispatch(CustomerState.actions.setCustomer(customer)).payload
    })
  }

  const setCustomer = (customer: Customer) => {
    return Promise.resolve(CustomerState.actions.setCustomer(customer).payload)
  }

  const addShippingAddress = (customer: Customer, address: Address) => {
    return service
      .addAddress(customer, address)
      .then(it => dispatch(CustomerState.actions.setCustomer(it)).payload)
  }

  return {
    setCustomer,
    fetchCustomer,
    addShippingAddress
  }
}
