//jsxhook

import {
  ArtDirectedImage,
  ButtonVariants,
  H2,
  H5,
  H6,
  ImgixFit,
  ImgixImage,
  TechnicalParagraph
} from "@sixty-six-north/ui-system"
import { useTranslation } from "react-i18next"
import { Box, Flex } from "theme-ui"
import Link from "../../components/Link"
import { useRegistrationLink } from "../../components/Links"

export const JoinTheAdventure = () => {
  const { t } = useTranslation("registration")
  return (
    <Flex
      sx={{
        bg: "blue.2",
        alignItems: "center",
        minHeight: "70vh",
        position: "relative"
      }}
    >
      <ArtDirectedImage
        imageAlt={""}
        hasGradientOverlay={true}
        sx={{ opacity: 0.5 }}
        imageNode={
          <ImgixImage
            imageAlt={""}
            imageUrl="https://images.66north.com/assets/44247"
            fit={ImgixFit.cover}
            sizes="50vw"
          />
        }
      />

      <Flex
        sx={{
          position: "relative",
          px: [24, null, null, null, 48],
          py: 48,
          maxWidth: "48ch",
          flexDirection: "column"
        }}
      >
        <Box>
          <H2 sx={{ color: "white.0" }}>
            {t("joinTheAdventure", {
              defaultValue: "Join the 66ºNorth adventure"
            })}
          </H2>
        </Box>
        <Box sx={{ mt: 16 }}>
          <H5 sx={{ color: "white.0" }}>{t("joinTheAdventureSubHeading")}</H5>
        </Box>
        {/* <Box sx={{ mt: 88, mb: 8 }}>
          <H6 sx={{ color: "white.0" }}>
            <Trans name="benefits">Benefits</Trans>
          </H6>
        </Box>
         <Box
          sx={{
            "& > * + *": {
              mt: 4
            }
          }}
        >
          <TechnicalParagraph sx={{ color: "white.0" }}>
            <Trans name="warrantyAndRepair">
              Easy access to warranty and repair service
            </Trans>
          </TechnicalParagraph>
          <TechnicalParagraph sx={{ color: "white.0" }}>
            <Trans name="discount">10% off your first purchase</Trans>
          </TechnicalParagraph>
          <TechnicalParagraph sx={{ color: "white.0" }}>
            <Trans name="earlyAccess">Early access to product launches</Trans>
          </TechnicalParagraph>
          <TechnicalParagraph sx={{ color: "white.0" }}>
            <Trans name="tales">Tales from the North</Trans>
          </TechnicalParagraph>
        </Box> */}
        <Box sx={{ mt: 24 }}>
          <Link
            {...useRegistrationLink()}
            sx={{ variant: `buttons.${ButtonVariants.secondary}` }}
          >
            {t("joinTheAdventure:register", { defaultValue: "Register" })}
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export default JoinTheAdventure
